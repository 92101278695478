.chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 70px;
  border-bottom: 1px solid #fafafa;
}

a {
  text-decoration: none;
  color: inherit;
}

.chat__details {
  flex: 1;
}

.chat__details p {
  color: gray;
}

.chat__image {
  margin-right: 20px;
}

.chat__timestamp {
  color: lightgray;
}
