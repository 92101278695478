html, body { height: 100%;  }

.match {
    background-color: #040507;
    height: 100vh
}

.match-container {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    vertical-align: middle;
}

.match-image {
    width: 80%;
    display: block;
    margin: 0 auto;
    padding-top: 50px;    
}