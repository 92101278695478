.card {
    position: relative;
    width: 600px;
    padding: 20px;
    max-width: 85vw;
    height: 50vh;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 18px 53px 0px rgba(0,0,0,0.3)
}

.card h3 {
    position: absolute;
    bottom: 10px;
    color: white;
}

.tinderCards__cardContainer {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
}

/* stacks cards on top of each other */
.swipe {
    position: absolute;
}

.profilePopup {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background: #fff;
    z-index: 99999;
    display: none;
    height: auto;
}

.profileClose {
    position: fixed !important;
    z-index: 999999;
    background: rgb(255 255 255 / 50%) !important;  
    margin-left: 5px !important;  
    margin-top: 5px !important;  
}

.buttonContainer {
    position: fixed;
    bottom: 20vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;    
}

.buttonContainer button {
    background: #fff;
}

.profilePopup h2 {
    color: #424242;
}

.profilePopup p {
    margin-top: 4px;
    color: #7C7C7C;
}

.deets {
    padding: 18px;
    border-bottom: 1px solid #ccc;
}

.bio {
    padding: 18px;
}

.profileButton {
    position: absolute !important;
    top: 0;
    left: 0;
}